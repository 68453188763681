import React from "react";
import { Link } from "gatsby";

import { HeadingLevel, Heading } from "baseui/heading";
import { Paragraph1, Label1 } from "baseui/typography";
import { StyledLink } from "baseui/link";
import { Button, KIND, SIZE } from "baseui/button";
import { ChevronRight } from "baseui/icon";
import { Accordion, Panel } from "baseui/accordion";
import { LocaleProvider } from "baseui";
import { ru_RU } from "../../locale/ru_RU";
import Page from "../../components/internal-page";
import data from "../../data/programs-frp.json";
import { Block } from "baseui/block";

const Programs = () => {
  const categories = data.data.map((cat) => {
    const { id, title, description, programs } = cat;
    return (
      <Block key={id.toString()}>
        <HeadingLevel>
          <HeadingLevel>
            <Heading>{title}</Heading>
            <Paragraph1>{description}</Paragraph1>
            <Accordion renderAll>
              {programs.data.map((program) => {
                const {
                  id,
                  name,
                  description,
                  interestRate,
                  loanTerm,
                  minAmount,
                  maxAmount,
                  projectBudget,
                  active,
                } = program;

                const relatedLink = program?.links?.related;
                const selfLink = program?.links?.self;
                if (!active) return;
                return (
                  <Panel title={`«${name}»`} key={id.toString()}>
                    <HeadingLevel>
                      <Heading styleLevel={4}>«{name}»</Heading>
                      <Label1>
                        от {minAmount.toLocaleString("ru-RU")}&nbsp;&#8381; до{" "}
                        {maxAmount.toLocaleString("ru-RU")}&nbsp;&#8381; для
                        проектов с бюджетом от{" "}
                        {projectBudget.toLocaleString("ru-RU")}
                        &nbsp;&#8381;
                      </Label1>
                      <Paragraph1>
                        Ставка: {interestRate}, Срок: до {loanTerm} мес.
                      </Paragraph1>
                      <Label1>Описание программы</Label1>
                      <Paragraph1>{description}</Paragraph1>
                      <Paragraph1>
                        {selfLink && (
                          <>
                            <Button
                              $as={Link}
                              to={selfLink.href}
                              kind={KIND.secondary}
                              size={SIZE.default}
                              endEnhancer={<ChevronRight size={20} />}
                            >
                              Подробнее
                            </Button>
                            &nbsp;&nbsp;
                          </>
                        )}
                        {cat.id === "2" && relatedLink && (
                          <StyledLink
                            href={relatedLink.href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            О программе на frprf.ru
                          </StyledLink>
                        )}
                      </Paragraph1>
                    </HeadingLevel>
                  </Panel>
                );
              })}
            </Accordion>
          </HeadingLevel>
        </HeadingLevel>
      </Block>
    );
  });
  return (
    <Page
      title="Программы Фонда развития промышленности"
      path="/frp/programs"
      parent="frp"
    >
      <LocaleProvider locale={ru_RU}>{categories}</LocaleProvider>
      <Paragraph1>
        За консультациями обращайтесь в региональный ФРП по телефону (8182)
        46-41-22 (доб. 107) или в{" "}
        <StyledLink
          href="https://frprf.ru/navigator-gospodderzhky/"
          target="_blank"
          rel="noopener noreferrer"
        >
          консультационный центр федерального ФРП
        </StyledLink>
      </Paragraph1>
    </Page>
  );
};

export default Programs;
